"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDev = exports.isLocal = void 0;
var isLocal = function () {
    return (window.location.hostname === 'localhost' ||
        window.location.hostname === 'arendus.rademar.ee' ||
        window.location.hostname === 'arendus.rademar-api.test' ||
        window.location.hostname === 'local.rademar.ee');
};
exports.isLocal = isLocal;
var isDev = function () {
    return (0, exports.isLocal)() || window.location.hostname.startsWith('haldus-dev.');
};
exports.isDev = isDev;
