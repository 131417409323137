"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var mobx_react_1 = require("mobx-react");
var mobx_1 = require("mobx");
var react_router_dom_1 = require("react-router-dom");
require("./styles/item.scss");
var Item = (function (_super) {
    __extends(Item, _super);
    function Item(props) {
        var _this = _super.call(this, props) || this;
        _this.orderItem = [];
        (0, mobx_1.makeObservable)(_this, {
            orderItem: mobx_1.observable,
            setOrderItem: mobx_1.action,
        });
        _this.confirmItem = _this.confirmItem.bind(_this);
        _this.rejectItem = _this.rejectItem.bind(_this);
        return _this;
    }
    Item.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, authentication, navigate;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, authentication = _a.authentication, navigate = _a.navigate;
                        return [4, authentication.check()];
                    case 1:
                        _b.sent();
                        if (!authentication.loggedIn) {
                            navigate('/');
                        }
                        this.load();
                        return [2];
                }
            });
        });
    };
    Item.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, warehouse, itemID, orderItem;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, warehouse = _a.warehouse, itemID = _a.itemID;
                        if (itemID === this.currentItem) {
                            return [2];
                        }
                        this.currentItem = itemID;
                        return [4, warehouse.orderItem(itemID)];
                    case 1:
                        orderItem = _b.sent();
                        this.setOrderItem(orderItem);
                        return [2];
                }
            });
        });
    };
    Item.prototype.setOrderItem = function (item) {
        this.orderItem = item;
    };
    Item.prototype.confirmItem = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, warehouse, navigate;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, warehouse = _a.warehouse, navigate = _a.navigate;
                        return [4, warehouse.confirmItem(this.currentItem)];
                    case 1:
                        _b.sent();
                        navigate('/pood/e-pood/tellimused');
                        return [2];
                }
            });
        });
    };
    Item.prototype.rejectItem = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, warehouse, navigate;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, warehouse = _a.warehouse, navigate = _a.navigate;
                        return [4, warehouse.rejectItem(this.currentItem)];
                    case 1:
                        _b.sent();
                        navigate('/pood/e-pood/tellimused');
                        return [2];
                }
            });
        });
    };
    Item.prototype.render = function () {
        var _this = this;
        if (!this.orderItem) {
            return null;
        }
        return (React.createElement("div", { className: "order-item" },
            React.createElement("h3", null,
                React.createElement(react_router_dom_1.Link, { to: '/pood/e-pood/tellimused' }, "Tagasi")),
            this.orderItem.map(function (item) { return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "left" },
                    React.createElement("div", { className: "table w-full" },
                        React.createElement("table", { className: "order-item" },
                            React.createElement("tbody", null,
                                React.createElement("tr", null,
                                    React.createElement("td", null, "Tellimus"),
                                    React.createElement("td", null, item.reference)),
                                React.createElement("tr", null,
                                    React.createElement("td", null, "Kuup\u00E4ev"),
                                    React.createElement("td", null, item.created_at)),
                                React.createElement("tr", null,
                                    React.createElement("td", null, "Staatus"),
                                    React.createElement("td", null, item.status)),
                                React.createElement("tr", null,
                                    React.createElement("td", { colSpan: 2 })),
                                React.createElement("tr", null,
                                    React.createElement("td", null, "Nimi"),
                                    React.createElement("td", null, item.product_title)),
                                React.createElement("tr", null,
                                    React.createElement("td", null, "Kategooria"),
                                    React.createElement("td", null, item.product_category)),
                                React.createElement("tr", null,
                                    React.createElement("td", { colSpan: 2 })),
                                React.createElement("tr", null,
                                    React.createElement("td", null, "Tootekood"),
                                    React.createElement("td", null, item.code)),
                                React.createElement("tr", null,
                                    React.createElement("td", null, "Suurus"),
                                    React.createElement("td", null, item.size)),
                                React.createElement("tr", null,
                                    React.createElement("td", null, "Kogus"),
                                    React.createElement("td", null, item.quantity)),
                                React.createElement("tr", null,
                                    React.createElement("td", null, "Pood"),
                                    React.createElement("td", null, item.shop)),
                                React.createElement("tr", null,
                                    React.createElement("td", { colSpan: 2 }, item.warehouse_address)),
                                React.createElement("tr", null,
                                    React.createElement("td", { colSpan: 2 }, item.priority)),
                                React.createElement("tr", null,
                                    React.createElement("td", { colSpan: 2 })),
                                React.createElement("tr", null,
                                    React.createElement("td", { colSpan: 2 },
                                        React.createElement("a", { href: item.url, target: "_blank" }, "Link"))),
                                React.createElement("tr", null,
                                    React.createElement("td", { colSpan: 2 })))),
                        React.createElement("div", { className: "buttons" },
                            React.createElement("div", { className: "confirm", onClick: _this.confirmItem }, "Kinnita"),
                            React.createElement("div", { className: "reject", onClick: _this.rejectItem }, "Ei kinnita")))),
                React.createElement("div", { className: "right" },
                    React.createElement("img", { src: item.product_image
                            ? item.product_image.urls['W800']
                            : undefined, alt: item.product_title })))); })));
    };
    Item = __decorate([
        (0, mobx_react_1.inject)('warehouse', 'authentication'),
        mobx_react_1.observer
    ], Item);
    return Item;
}(React.Component));
exports.default = Item;
