"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var mobx_react_1 = require("mobx-react");
var mobx_1 = require("mobx");
var react_router_dom_1 = require("react-router-dom");
require("./styles/index.scss");
var Categories = (function (_super) {
    __extends(Categories, _super);
    function Categories(props) {
        var _this = _super.call(this, props) || this;
        _this.currentSalesReportId = '';
        _this.categories = [];
        (0, mobx_1.makeObservable)(_this, {
            categories: mobx_1.observable,
            setData: mobx_1.action,
        });
        return _this;
    }
    Categories.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, authentication, navigate;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, authentication = _a.authentication, navigate = _a.navigate;
                        return [4, authentication.check()];
                    case 1:
                        _b.sent();
                        this.updateCategories();
                        if (!authentication.loggedIn) {
                            navigate('/');
                        }
                        return [2];
                }
            });
        });
    };
    Categories.prototype.componentDidUpdate = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.updateCategories();
                return [2];
            });
        });
    };
    Categories.prototype.updateCategories = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, revaluation, saleReportId, sale_report_id, categories;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, revaluation = _a.revaluation, saleReportId = _a.saleReportId;
                        sale_report_id = saleReportId;
                        if (sale_report_id === this.currentSalesReportId) {
                            return [2];
                        }
                        this.currentSalesReportId = sale_report_id;
                        return [4, revaluation.categories(sale_report_id)];
                    case 1:
                        categories = _b.sent();
                        this.setData('categories', categories);
                        return [2];
                }
            });
        });
    };
    Categories.prototype.setData = function (target, data) {
        this[target] = data;
    };
    Categories.prototype.render = function () {
        var _this = this;
        var categoriesOverview = null;
        if (this.categories) {
            categoriesOverview = (React.createElement("div", { className: "categories-report" },
                React.createElement("table", { className: "table w-full" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: "text-align left" }, "ID"),
                            React.createElement("th", { className: "text-align left" }, "Kategooria"),
                            React.createElement("th", null, "Marginal"),
                            React.createElement("th", null, "Laoseis tk"),
                            React.createElement("th", null, "Laoseis \u20AC"),
                            React.createElement("th", null, "L\u00E4bim\u00FC\u00FCk"),
                            React.createElement("th", null, "\u00DClevaade"),
                            React.createElement("th", null, "Staatus"),
                            React.createElement("th", null, "Valikud"))),
                    React.createElement("tbody", null, this.categories.map(function (category) { return (React.createElement("tr", null,
                        React.createElement("td", { className: "text-align left" }, category.category_id),
                        React.createElement("td", { className: "text-align left" }, category.category_title),
                        React.createElement("td", null,
                            category.category_margin,
                            "%"),
                        React.createElement("td", null, category.stock_qty),
                        React.createElement("td", null, category.stock_eur),
                        React.createElement("td", null,
                            category.qty_sell_through,
                            "%"),
                        React.createElement("td", null,
                            category.decided_qty,
                            " / ",
                            category.products_qty),
                        React.createElement("td", null, category.status == true && React.createElement("i", { className: "gg-check" })),
                        React.createElement("td", null,
                            React.createElement(react_router_dom_1.Link, { to: "/ost/umberhindlused/raport/".concat(_this.currentSalesReportId, "/kategooria/").concat(category.category_id) }, "Tooted")))); })))));
        }
        return (React.createElement("div", { className: "purchase-revaluation-category" }, categoriesOverview));
    };
    Categories = __decorate([
        (0, mobx_react_1.inject)('authentication', 'revaluation'),
        mobx_react_1.observer
    ], Categories);
    return Categories;
}(React.Component));
exports.default = Categories;
