"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Env_1 = require("./Env");
var DEFAULT_CONFIG = {
    api: 'https://api-dev.rademar.ee/api/',
};
var finalConfig = DEFAULT_CONFIG;
if (!(0, Env_1.isDev)()) {
    finalConfig = Object.assign({}, DEFAULT_CONFIG, {
        api: 'https://api.rademar.ee/api/',
    });
}
exports.default = finalConfig;
